import styled from 'styled-components';
import breakpoints from '../utils/breakpoints';

const Nav = styled.nav`
  ul {
    display: inline-flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    &:first-child a {
      padding-left: 0;
    }
  }

  a {
    display: block;
    color: var(--color-white);
    padding: var(--spacing-xs) var(--spacing-s);
  }
`;

export const MobileNav = styled.nav`
  ${breakpoints.large`
    display: none;
  `};

  ul {
    list-style: none;
    padding: 0;
    margin: var(--spacing-s) 0 0;
  }

  li {
  }

  a {
    display: inline-block;
    color: var(--color-white);
    text-transform: uppercase;
    padding: var(--spacing-xs) var(--spacing-s);
  }
`;

export default Nav;
