import React, { Component } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { FaEnvelope, FaPhone, FaXing, FaFacebookF } from "react-icons/fa";
import { BiMenu, BiX } from "react-icons/bi";
import Nav, { MobileNav } from "./Nav";
import breakpoints from "../utils/breakpoints";

const HeaderStyles = styled.header`
  position: sticky;
  top: 0;
  padding: var(--spacing-s) var(--spacing);
  z-index: var(--z-index-xxl);
  background-color: var(--color-dark-gray);
  box-shadow: var(--shadow-s);

  strong {
    color: var(--color-primary);
  }

  .nav {
    display: none;

    ${breakpoints.large`
      display: block;
      `};
  }

  .hidden {
    display: none;
  }
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MenuButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-s) 0 0;
  color: var(--color-primary);
  background: none;
  border: none;

  ${breakpoints.large`
    display: none;
  `};

  svg {
    font-size: var(--font-size-xl);
    margin-right: var(--spacing-xs);
    margin-left: 0;
  }
`;

const ContactDataList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    & + li {
      margin-left: var(--spacing);
    }
  }

  a {
    display: flex;
    align-items: center;
    color: var(--color-white);
    padding: var(--spacing-xs) var(--spacing-s);

    span {
      display: none;
      margin-left: var(--spacing-s);

      ${breakpoints.xlarge`
        display: inline;
      `};
    }
  }

  svg {
    font-size: var(--font-size-l);
    color: var(--color-primary);

    ${breakpoints.xlarge`
      font-size: var(--font-size);
    `};
  }
`;

const navItems = [
  {
    id: "1",
    text: "Leistungen",
    url: "/#leistungen",
  },
  {
    id: "2",
    text: "Beratung",
    url: "/#beratung",
  },
  {
    id: "3",
    text: "Über mich",
    url: "/#ueber-mich",
  },
  {
    id: "4",
    text: "Kontakt",
    url: "/#kontakt",
  },
];

const ContactData = () => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author {
            email
            phone
            phonelink
          }
        }
      }
    }
  `);

  const {
    author: { phone, phonelink, email },
  } = siteMetadata;

  return (
    <ContactDataList>
      <li>
        <a href={`tel:${phonelink}`}>
          <FaPhone />
          <span>{phone}</span>
        </a>
      </li>
      <li>
        <a href={`mailto:${email}`}>
          <FaEnvelope />
          <span>{email}</span>
        </a>
      </li>
    </ContactDataList>
  );
};

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { showNav: false };
    this.handleClick = this.handleClick.bind(this);
    this.closeNav = this.closeNav.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      showNav: !prevState.showNav,
    }));
  }

  closeNav() {
    this.setState(() => ({
      showNav: false,
    }));
  }

  render() {
    // the modal you will toggle on and off
    const mobileNav = (
      <MobileNav>
        <ul>
          <li>
            <Link to="/">
              <strong>A. Burkhardt</strong>
            </Link>
          </li>
          {navItems.map((item) => (
            <li key={`headerItem${item.id}`}>
              <Link to={item.url} onClick={this.closeNav}>
                {item.text}
              </Link>
            </li>
          ))}

          <li>
            <Link to="/datenschutz" onClick={this.closeNav}>
              Datenschutz
            </Link>
          </li>
          <li>
            <Link to="/impressum" onClick={this.closeNav}>
              Impressum
            </Link>
          </li>
        </ul>
      </MobileNav>
    );

    return (
      <HeaderStyles>
        <TopBar>
          <MenuButton onClick={this.handleClick}>
            {this.state.showNav ? <BiX /> : <BiMenu />} Menü
          </MenuButton>

          <Nav className="nav">
            <ul>
              <li>
                <Link to="/">
                  <strong>A. Burkhardt</strong>
                </Link>
              </li>
              {navItems.map((item) => (
                <li key={`headerItem${item.id}`}>
                  <Link to={item.url}>{item.text}</Link>
                </li>
              ))}
            </ul>
          </Nav>

          <ContactData />
        </TopBar>

        {this.state.showNav ? mobileNav : ""}
      </HeaderStyles>
    );
  }
}
