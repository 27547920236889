import { createGlobalStyle } from "styled-components";

import font from "../assets/fonts/montserrat.woff";

const Typography = createGlobalStyle`
@font-face {
  font-family: Montserrat;
  src: url(${font});
}

/* Base */
h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
  font-size: var(--font-size);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--secondary-font);
  font-weight: var(--font-weight);
  line-height: var(--line-height-s);
  color: var(--color-primary);
  margin-bottom: var(--spacing);
}

h1, .h1 {
  font-size: var(--font-size-xxxl);
  font-weight: var(--font-weight-light);
  margin-bottom: 0;
  /* letter-spacing: 2px; */
}

h2, .h2 {
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-light);
  margin-bottom: var(--spacing-l);
  /* letter-spacing: 4px; */
}

h3, .h3 {
  font-size: var(--font-size-xl);
  /* letter-spacing: 1px; */
}

h4, .h4 {
  font-size: var(--font-size-l);
  /* letter-spacing: 1px; */
}

h6, .h6 {
  font-weight: normal;
  font-style: italic;
}

p {
  margin-bottom: var(--spacing);

  &:last-child {
    margin-bottom: 0;
  }
}

small {
  font-size: var(--font-size-s);
}


/* Links */
a, .link {
  color: var(--color-primary);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

  /* mark, .mark {
    background: var(--secondary);
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  } */

`;

export default Typography;
