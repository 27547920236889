import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Nav from './Nav';
import breakpoints from '../utils/breakpoints';

const FooterStyles = styled.footer`
  padding: var(--spacing);

  ${breakpoints.medium`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-s) var(--spacing);
  `};

  ul {
    margin-bottom: var(--spacing-s);

    ${breakpoints.medium`
      margin-bottom: 0;
    `};

    a {
      color: var(--color-primary);
    }
  }

  p {
    margin: 0;
    order: -1;
  }
`;

const navItems = [
  {
    id: '1',
    text: 'Datenschutz',
    url: '/datenschutz',
  },
  {
    id: '2',
    text: 'Impressum',
    url: '/impressum',
  },
];

export default function Footer() {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author {
            name
          }
        }
      }
    }
  `);

  const {
    author: { name },
  } = siteMetadata;

  return (
    <FooterStyles>
      <Nav>
        <ul>
          {navItems.map((item) => (
            <li key={`footerItem${item.id}`}>
              <Link to={item.url}>{item.text}</Link>
            </li>
          ))}
        </ul>
      </Nav>

      <p>
        &copy; {name} {new Date().getFullYear()}. All rights reserved.
      </p>
    </FooterStyles>
  );
}
